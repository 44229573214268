import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Divider, Popconfirm, Row, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FileImageFilled, FileImageOutlined, FilePptOutlined, FilterFilled, ProfileOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import moment from 'moment';

const SaleList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [saleList, setSaleList] = useState([]);
	const [saleReturnList, setSaleReturnList] = useState([]);	
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const [plants, setPlants] = useState([]);
	const [salesItemList, setSalesItemList] = useState([]);
    const [salesReturnItemList, setSalesReturnItemList] = useState([]);
	const [estimationData, setEstimationData] = useState({});
	const [itemTypes, setItemTypes] = useState([]);
	const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
    });

	const tableColumnSearch = columnSearch(searchInput);

	const formik = useFormik({
		initialValues: {

		}
	});
	
	useEffect(() => {
		setLoading(true);
		fetchSalesList();
	}, []);

	const fetchSalesList = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/salesOrder/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setSaleList(response?.data);
					// setSaleReturnList(response?.data?.salesReturn);
				} else {
					console.error(
						'Error retrieving sales data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sales data:', error);
			});
	};

	const fetchSalesEstimationData = (salesOrderNo) => {
		httpCall({
			method: 'GET',
			url: '/api/sales/estimation?sales_order_no=' + salesOrderNo,
		})
			.then((response) => {
				setLoading(false);
				if (response) {					
					setItemTypes(response?.data.itemTypes);
					setSalesItemList(response?.data.salesItemList);
					setSalesReturnItemList(response?.data.salesReturnItemList);
					setEstimationData(response?.data.soDetails);
					setTimeout(() => handlePrint(), 0);
				} else {
					console.error(
						'Error retrieving sales estimation data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sales data:', error);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Sales Data Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/sales/form', { state: { id } })
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/sales/delete/all?sales_id='+ id,
		}).then(reponse => {
			openNotification();
			fetchSalesList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
        {
			title: 'Sales Order Nr',
			dataIndex: 'sales_order_no',
			key: 'sales_order_no',
			...tableColumnSearch('sales_order_no'),
			align: 'center',
			width: 150,
		},
		{
			title: 'Cost Center Name',
			dataIndex: 'cost_center_name',
			key: 'cost_center_name',
			...tableColumnSearch('cost_center_name'),
			align: 'center',
			width: 200
		},
		{
			title: 'Customer Name',
			dataIndex: 'customer_name',
			key: 'customer_name',
			align: 'center',
			width: 200
		},
		{
			title: 'Customer Phone Nr',
			dataIndex: 'mobile_number',
			key: 'mobile_number',
			align: 'center',
			width: 200
		},
        {
			title: 'Total Bill Amount',
			dataIndex: 'total_sales_amount',
			key: 'total_sales_amount',
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				saleList.length >= 1 ? (
						<Space size="middle">
							<ProfileOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} title='Print Estimation' onClick={() => fetchSalesEstimationData(record.sales_order_no)} />
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.sales_order_no)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.sales_order_no)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	const salesReturnColumns = [
        {
			title: 'Item Name',
			dataIndex: 'item_name',
			key: 'item_name',
			...tableColumnSearch('item_name'),
			align: 'center',
			width: 300
		},
		{
			title: 'Cost Center Name',
			dataIndex: 'cost_center_name',
			key: 'cost_center_name',
			...tableColumnSearch('cost_center_name'),
			align: 'center',
			width: 200
		},
		{
			title: 'Customer Name',
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			width: 200
		},
		{
			title: 'Phone No',
			dataIndex: 'no',
			key: 'no',
			align: 'center',
			width: 200
		},
        {
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				saleList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
						</Space>
				) : null			
		  },
	];

	const tab1 = () => {
		return (
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Table 
						dataSource={saleList}
						columns={columnsNew}
						rowKey="sales_order_no"
						pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
						scroll={{ y: 570 }}
						style={{fontFamily: 'Poppins'}}/>
				</Spin>
		)
	}

	const tab2 = () => {
		return (
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Table 
						dataSource={saleReturnList}
						columns={salesReturnColumns}
						rowKey="sales_return_id"
						pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
						scroll={{ y: 570 }}
						style={{fontFamily: 'Poppins'}}/>
				</Spin>
		)
	}

	const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;

    const salesAmountWOTax = () => parseFloat(estimationData?.total_sales_item_amount) - salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0);

	const totalTax = () => salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0) / 2;

    const totalOldGoldWeight = () => salesReturnItemList.reduce((acc, next) => parseFloat(acc) + parseFloat(next.weight), 0) || 0;  

	const getPageMargins = () => {
		return `@page { size: landscape; margin: 20px !important; }`;
	};

	const EstimationReport = () => {
		return (
			<>
                <div style={{display: 'none'}}>
                    <div className='estimation-container' ref={componentRef} style={{border: '1px solid #000000'}}>
						<style>{getPageMargins()}</style>
						<Row style={{padding: '10px'}}>
							<Col span={20} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<span style={{fontSize: '24px', fontWeight: '700'}}>
									{ estimationData?.plant_name }
								</span>
								<span style={{fontSize: '18px', fontWeight: '600'}}>
									{`${estimationData?.address}, ${estimationData?.city}-${estimationData?.zipcode}`}
								</span>
								<span style={{fontSize: '18px', fontWeight: '600'}}>
									Phone : { estimationData?.phone_no }
								</span>
							</Col>
							<Col span={4} style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
								<span className="saleBillText">ESTIMATION</span> 
								<span className="saleBillText">Date : {moment(estimationData?.sales_order_date).format('DD/MM/YYYY')}</span> 
								<span className="saleBillText">E.No : {estimationData?.sales_order_no_inc}</span> 
								<span className="saleBillText">S.code : {estimationData?.sales_order_no_inc}</span> 
							</Col>
                        </Row>
						<Row className="gutter-row" style={{borderTop: '1px solid #000000', padding: '10px 10px'}}>
							<Col span={14}>
								<span style={{fontSize: '18px'}}>
									Customer: { estimationData?.customer_name }
								</span>
							</Col>
							<Col span={10} style={{width: '100%'}}>
								<Row className="gutter-row">
									<Col span={7} style={{display: 'flex', flexDirection:' column', gap: '10px', alignItems: 'end'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>Gold Rate</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>Silver Rate</span> 
									</Col>
									<Col span={1} style={{display: 'flex', flexDirection:' column', gap: '10px', alignItems: 'end'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>:</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>:</span> 
									</Col>
									<Col span={15} offset={1} style={{display: 'flex', flexDirection:' column', gap: '10px'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>{getCurrentRate('GOLD')}</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>{getCurrentRate('SILVER')}</span> 
									</Col>
								</Row>
							</Col>
                        </Row>
						<Row className="gutter-row" style={{borderTop: '1px solid #000000', width: '100%', fontWeight: '600'}}>
							<Col span={2} className='pl-10px pt-5px pb-5px'>
								<span className="saleBillText ">Sno</span> 
							</Col>
							<Col span={5} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Particulars</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Qty</span> 
							</Col>
							<Col span={3} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Weight</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">St_wt(-)</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Wst</span> 
							</Col>
							<Col span={3} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">MC</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">HM/SC</span> 
							</Col>
							<Col span={3} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Amount</span> 
							</Col>
                        </Row>
						{salesItemList?.map((item, index) => (
							<>
							<Row key={index} className="gutter-row" style={{borderTop: index === 0 ? '1px solid #000000' : null, borderBottom: '1px solid #000000', width: '100%', height: salesItemList?.length - 1 === index ? '150px' : null}}>
								<Col span={2} className='pl-10px pt-5px'>
									<span className="saleBillText">{index + 1}</span> 
								</Col>
								<Col span={5} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">{item.itemname}</span> 
								</Col>
								<Col span={2} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">{item.pcs}</span> 
								</Col>
								<Col span={3} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">{item.weight}</span> 
								</Col>
								<Col span={2} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">0.000</span> 
								</Col>
								<Col span={2} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">{item.waste}</span> 
								</Col>
								<Col span={3} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">{item.mc_amount}</span> 
								</Col>
								<Col span={2} className='border-left pl-10px pt-5px'>
									<span className="saleBillText">0.000</span> 
								</Col>
								<Col span={3} className='border-left pt-5px pr-10px' style={{textAlign: 'end'}}>
									<span className="saleBillText">{(item.amount - item.tax_amount).toFixed(2)}</span> 
								</Col>
                        	</Row>
							</>
						))}

						<Row className="gutter-row" style={{width: '100%'}}>
							<Col span={14} style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px'}}>
								<span className="saleBillText pl-10px" style={{fontSize: '16px', fontWeight: '600'}}>Old Details</span> 
								<Row className="gutter-row">
									<Col span={10} style={{display: 'flex', flexDirection:' column', gap: '10px', alignItems: 'end'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>OG&nbsp;&nbsp;&nbsp;{totalOldGoldWeight()}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{totalOldGoldWeight()}&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;6030.000</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>Old Gold(Tot)&nbsp;&nbsp;&nbsp;{totalOldGoldWeight()}&nbsp;Gm</span> 
									</Col>
									<Col span={1} style={{display: 'flex', flexDirection:' column', gap: '10px', alignItems: 'end'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>=</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>=</span> 
									</Col>
									<Col span={12} offset={1} style={{display: 'flex', flexDirection:' column', gap: '10px'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>{(totalOldGoldWeight() * 6030).toFixed(2)}</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>{(totalOldGoldWeight() * 6030).toFixed(2)}</span> 
									</Col>
								</Row> 
							</Col>
							<Col span={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
								<span className="saleBillText" style={{marginTop: '10px'}}>Total</span> 
								<span className="saleBillText" style={{marginTop: '3px'}}>CGST : </span> 
								<span className="saleBillText" style={{marginTop: '3px'}}>SGST : </span> 
								<span className="saleBillText" style={{marginTop: '3px'}}>Discount : (-)</span>								
							</Col>
							<Col span={3} offset={1} className='pr-10px' style={{borderLeft: '1px solid #000000', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
								<span className="saleBillText" style={{marginTop: '10px'}}>{salesAmountWOTax().toFixed(2)}</span> 
								<span className="saleBillText" style={{marginTop: '3px'}}>{totalTax().toFixed(2)}</span> 
								<span className="saleBillText" style={{marginTop: '3px'}}>{totalTax().toFixed(2)}</span>
								<span className="saleBillText" style={{marginTop: '3px'}}>{estimationData?.discount || 0}</span>								
							</Col>
                        </Row>
						<Row className="gutter-row" style={{width: '100%', marginBottom: '20px'}}>
							<Col span={20} style={{display: 'flex', justifyContent: 'end'}}>
								<span className="saleBillText" style={{fontSize: '16px', fontWeight: '600', marginTop: '10px'}}>Net Amount</span>
							</Col>
							<Col span={3} offset={1} className='pr-10px' style={{borderTop: '1px solid #000000', borderLeft: '1px solid #000000', borderBottom: '1px solid #000000', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
								<span className="saleBillText" style={{margin: '5px 0px',}}>{(estimationData?.total_sales_item_amount || 0).toFixed(2)}</span>
							</Col>
                        </Row>
						<Row className="gutter-row" style={{width: '100%', marginBottom: '50px'}}>
							<Col span={19} style={{display: 'flex', justifyContent: 'end'}}>
								<span className="saleBillText" style={{fontSize: '16px', fontWeight: '600'}}>Old {totalOldGoldWeight()} Gm (-)</span>
							</Col>
							<Col span={3} offset={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
								<span className="saleBillTex pr-10px" style={{fontSize: '16px'}}>{(totalOldGoldWeight() * 6030).toFixed(2)}</span>
							</Col>
                        </Row>
						<Row className="gutter-row" style={{width: '100%'}}>
							<Col span={19} style={{display: 'flex', justifyContent: 'end'}}>
								<span className="saleBillText pt-5px" style={{fontSize: '16px', fontWeight: '600'}}>Net Total</span>
							</Col>
							<Col span={3} offset={2} style={{borderTop: '1px solid #000000', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
								<span className="saleBillText pr-10px" style={{fontSize: '18px', margin: '5px 0px'}}>{(estimationData?.total_sales_amount || 0).toFixed(2)}</span>
							</Col>
                        </Row>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<PageTitle
				imagesrc={SalesImage}
				pageTitle="Sales List"
				buttonList={[{
					buttonName: 'New Sale Entry',
					className: 'btn-create',
					url: '/slj/sales/form'
				}]}
			/>
			{contextHolder}
			{EstimationReport()}
			{<div className='vendorListArea'>
			<Tabs type='card' 
				tabBarGutter={10} 
				size='small' 
				style={{fontFamily: 'poppins'}} 
				items={[{title: 'Sales', table: tab1()}, {title: 'Old Gold Return', table: tab2()}].map((tab, i) => {
				return {
					key: i,
					label: tab.title,
					children: tab.table,
				};
			})}>
			</Tabs>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default SaleList;
