import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification } from 'antd';
import { CheckCircleFilled, WarningOutlined } from '@ant-design/icons';
import CostCenterImage from '../../assets/images/cost-center.png';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import costCenterFormFields from './costCenterFormFields';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';

const CostCenterForm = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(1);
	const [api, contextHolder] = notification.useNotification();

	useEffect(() => {
		if(location?.state?.id) {
			httpCall({
				method: 'GET',
				url: '/api/costcenter/get?id=' + location?.state?.id,
			})
				.then((response) => {
					formik.setValues(response.data[0])				
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, []);

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

	const createCostCenter = (values, handleReset) => {
		httpCall({
			method: 'POST',
			url: '/api/costcenter/create',
			payload: values,
		})
			.then((response) => {
				if (response.data.success) {
					openNotification('Cost Center Created Successfully, Goto list view or Create another one. ');		
					handleReset();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const updateCostCenter = (values) => {
		httpCall({
			method: 'PUT',
			url: '/api/costcenter/edit',
			payload: {...values, id: location?.state?.id},
		})
			.then((response) => {
				if (response.data.success) {
					openNotification('Cost Center Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/cost-center'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const formik = useFormik({
		initialValues: {
			cost_center_name: '',
			address_1: '',
			address_2: '',
			phone: '',
			email: '',
			pan_card_no: '',
			active: false,
			bank_details_1: '',
			account_details_1: 0,
			bank_details_1_branch_details: '',
			bank_details_1_ifsc_code: '',
			bank_details_2: '',
			account_details_2: 0,
			bank_details_2_branch_details: '',
			bank_details_2_ifsc_code: '',
			prefix_no: '',
		},
		onSubmit: (values, {resetForm}) => {
			if(values.cost_center_name === '' || values.phone === '') {
				openErrorNotification('Please Enter Required Fields...');
				return;
			}
			if(location?.state?.id) {
				updateCostCenter(values);
			} else {
				createCostCenter(values, resetForm);
			}
		},
	});

	const createNewFormSection = (sections) => {
		return (
			<>
				{sections.map((section, index) => (
					<Row key={index} gutter={16} className="gutter-row">
						{section.map((field, index) => (
							<Col span={field.colSpan} className="gutter-box">
								<div>
									<span className="hintText">{field.label}</span>
									{field.isRequired && (
										<span className="mandatory"></span>
									)}
								</div>
	
								{field.type === 'text' ? (
									<Input
										type={field.type}
										id={field.id}
										style={{ width: '100%' }}
										onChange={formik.handleChange}
										value={formik.values[field.id]}
									/>
								) : (
									<Checkbox
										id={field.id}
										style={{ width: '100%' }}
										onChange={formik.handleChange}
										checked={formik.values[field.id]}
									/>
								)}
								{formik.touched[field.id] && formik.errors[field.id] ? (
									<div>
										<WarningOutlined
											style={{ color: 'red' }}
										/>
										<span
											style={{
												fontFamily: 'Poppins',
												fontSize: '12px',
												color: 'red',
												paddingLeft: '5px',
											}}
										>
											{formik.errors[field.id]}
										</span>
									</div>
								) : null}
							</Col>
						))}
					</Row>
				))}
			</>
		);
	};
	return (
		<>
		<PageTitle
			imagesrc={CostCenterImage}
			pageTitle="Create Cost Center"
			buttonList={[{
				buttonName: 'Back',
				className: 'btn-back',
				url: '/slj/cost-center'
			}]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					{/* <div className="createGeneralGrid"> */}
					{createNewFormSection(
						costCenterFormFields.section1
					)}
					{/* </div> */}
					<div className="DetailsBox">
						<div className="Tabs">
							<div
								className={`individualTab ${
									activeTab === 1 ? 'active' : ''
								}`}
								onClick={() => setActiveTab(1)}
							>
								Bank Details 1
							</div>
							<div
								className={`individualTab ${
									activeTab === 2 ? 'active' : ''
								}`}
								onClick={() => setActiveTab(2)}
							>
								Bank Details 2
							</div>
						</div>
						{activeTab === 1 && (
							<div className="individualTabDetails">
								{createNewFormSection(
									costCenterFormFields.section2
								)}
							</div>
						)}
						{activeTab === 2 && (
							<div className="individualTabDetails">
								{createNewFormSection(
									costCenterFormFields.section3
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);
};

export default CostCenterForm;
