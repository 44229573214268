import CostCenterList from './CostCenter/CostCenterList';
import CostCenterForm from './CostCenter/CostCenterCreate';
import ItemMenu from './Item/ItemMenu';
import Login from './Login';
import MainLayout from './MainLayout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CategoryList from './Item/ItemCategory/CategoryList';
import SubCategoryList from './Item/ItemCategory/SubCategoryList';
import ItemDataList from './Item/ItemData/ItemDataList';
import ItemDataForm from './Item/ItemData/ItemDataCreate';
import VendorList from './Vendor/VendorList';
import VendorForm from './Vendor/VendorCreate';
import CustomerList from './Customer/CustomerList';
import CustomerForm from './Customer/CustomerCreate';
import ItemTypeList from './Item/ItemType/ItemTypeList';
import SaleList from './Sales/SaleList';
import SalesForm from './Sales/SaleCreate';
import PurchaseList from './Purchase/PurchaseList';
import PurchaseForm from './Purchase/PurchaseCreate';
import PurchasePayment from './Purchase/PurchasePayment';
import SalesReceipt from './Sales/SalesReceipt';
import PageUnderConstruction from './common/PageUnderConstruction';
import UOMList from './UOM/UOMList';
import SalesReceiptList from './Sales/SalesReceipt';
import SalesReceiptCreate from './Sales/SalesReceiptCreate';
import PurchaseReceiptList from './Purchase/PurchaseReceiptList';
import Payment from './settings/Payment';
import SaleReport from './Sales/SaleReport';
import Accounts from './settings/Accounts';
import SettingsMenu from './settings/SettingsMenu';
import PlantList from './Store/PlantList';
import PlantForm from './Store/PlantCreate';
import StockList from './Stock/StockList';
import MCPayment from './Purchase/MCPayment';
import MCReceiptList from './Purchase/MCReceiptList';

const Home = () => {
	const appRouterProvider = createBrowserRouter([
		{
			path: '/',
			element: <Login />,
		},
		{
			path: '/slj',
			element: <MainLayout />,
			children: [
				{ 
					path: '*',
					element: <PageUnderConstruction />
				},
				{ 
					path: 'cost-center',
					element: <CostCenterList /> 
				},
				{ 
					path: 'cost-center/form',
					element: <CostCenterForm /> 
				},
				{ 
					path: 'items',
					element: <ItemMenu /> 
				},
				{ 
					path: 'category',
					element: <CategoryList /> 
				},
				{ 
					path: 'subcategory',
					element: <SubCategoryList /> 
				},
				{ 
					path: 'item-data',
					element: <ItemDataList /> 
				},
				{ 
					path: 'item-type',
					element: <ItemTypeList /> 
				},
				{ 
					path: 'item-data/form',
					element: <ItemDataForm /> 
				},
				{ 
					path: 'vendor',
					element: <VendorList /> 
				},
				{ 
					path: 'vendor/form',
					element: <VendorForm /> 
				},
				{ 
					path: 'customer',
					element: <CustomerList /> 
				},
				{ 
					path: 'customer/form',
					element: <CustomerForm /> 
				},
				{ 
					path: 'sales',
					element: <SaleList /> 
				},
				{ 
					path: 'sales/form',
					element: <SalesForm /> 
				},
				{ 
					path: 'purchase',
					element: <PurchaseList /> 
				},
				{ 
					path: 'purchase/form',
					element: <PurchaseForm /> 
				},
				{ 
					path: 'purchase-receipt',
					element: <PurchaseReceiptList /> 
				},
				{ 
					path: 'purchase-receipt/form',
					element: <PurchasePayment /> 
				},
				{ 
					path: 'mc-receipt',
					element: <MCReceiptList /> 
				},
				{ 
					path: 'mc-receipt/form',
					element: <MCPayment /> 
				},
				{ 
					path: 'sales-receipt',
					element: <SalesReceiptList /> 
				},
				{ 
					path: 'sales-receipt/form',
					element: <SalesReceiptCreate /> 
				},
				{ 
					path: 'uom',
					element: <UOMList/> 
				},
				{ 
					path: 'settings',
					element: <SettingsMenu/> 
				},
				{ 
					path: 'payment',
					element: <Payment/> 
				},
				{ 
					path: 'accounts',
					element: <Accounts/> 
				},
				{ 
					path: 'sales-report',
					element: <SaleReport /> 
				},
				{ 
					path: 'plant',
					element: <PlantList /> 
				},
				{ 
					path: 'plant/form',
					element: <PlantForm /> 
				},
				{ 
					path: 'stock',
					element: <StockList /> 
				},
			],
		},
	]);

	return (
		<div>
			<RouterProvider router={appRouterProvider} />
		</div>
	);
};

export default Home;
