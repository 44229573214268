import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import VendorImage from '../../assets/images/vendor.png';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import vendorFormFields from './vendorFormFields';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';

const VendorForm = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(1);
	const [api, contextHolder] = notification.useNotification();

	const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const formik = useFormik({
		initialValues: {
            company_name: '',
            mailing_name: '',
            address: '',
            country: '',
            state: '',
            city: '',
            zip_code: '',
            website: '',
            pan_card_no: '',
            gst_register_type: '',
            gst_no: '',
            email: '',
            mobile_number: '',
            phone_number: '',
            credit_days: '',
            vendor_job_type: '',
            contact_name: '',
            designation: '',
            department: '',
            contact_mobile_number: '',
            contact_email: '',
            experience: '',
            education_details: '',
            account_holder_name: '',
            account_no: '',
            confirm_account_no: '',
            ifsc_code: '',
            bank_name: '',
            branch: '',
		},
		onSubmit: (values, {resetForm}) => {
			if(values.mobile_number === '' || values.company_name == '') {
				openErrorNotification('Please Enter Required Fields...');
				return;
			}
			if(location?.state?.id) {
				updateCostCenter(values);
			} else {
				createVendor(values, resetForm);
			}
		},
	});


	useEffect(() => {
		if(location?.state?.id) {
			httpCall({
				method: 'GET',
				url: '/api/vendor/getVendor?id=' + location?.state?.id,
			})
				.then((response) => {
					formik.setValues(response.data)				
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, []);

    useEffect(() => {
        formik.setFieldValue('mailing_name', formik.values.company_name)
	}, [formik.values.company_name]);

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const createVendor = (values, handleReset) => {
		httpCall({
			method: 'POST',
			url: '/api/vendor/create',
			payload: values,
		})
			.then((response) => {
				if (response) {
					openNotification('Vendor Created Successfully, Goto list view or Create another one. ');		
					handleReset();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const updateCostCenter = (values) => {
		httpCall({
			method: 'PUT',
			url: '/api/vendor/edit',
			payload: {...values, vendorId: location?.state?.id},
		})
			.then((response) => {
				if (response) {
					openNotification('Vendor Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/vendor'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const createNewFormSection = (sections) => {
		return (
			<>
				{sections.map((section, index) => (
					<Row key={index} gutter={16} className="gutter-row">
						{section.map((field, index) => (
							<Col span={field.colSpan} className="gutter-box">
								<div>
									<span className="hintText">{field.label}</span>
									{field.isRequired && (
										<span className="mandatory"></span>
									)}
								</div>
	
								{field.type === 'text' ? (
									<Input
										type={field.type}
										id={field.id}
										style={{ width: '100%' }}
                                        disabled={field.disabled}
										onChange={formik.handleChange}
										value={formik.values[field.id]}
									/>
								) : (
									<Checkbox
										id={field.id}
										style={{ width: '100%' }}
										onChange={formik.handleChange}
										checked={formik.values[field.id]}
									/>
								)}
							</Col>
						))}
					</Row>
				))}
			</>
		);
	};
	return (
		<>
		<PageTitle
			imagesrc={VendorImage}
			pageTitle="Create Vendor"
			buttonList={[{
				buttonName: 'Back',
				className: 'btn-back',
				url: '/slj/vendor'
			}]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
                    {createNewFormSection(
						vendorFormFields.section1
					)}
					<div className="DetailsBox">
						<div className="Tabs">
							<div
								className={`individualTab ${
									activeTab === 1 ? 'active' : ''
								}`}
								onClick={() => setActiveTab(1)}
							>
								Contact Details
							</div>
							<div
								className={`individualTab ${
									activeTab === 2 ? 'active' : ''
								}`}
								onClick={() => setActiveTab(2)}
							>
								Bank Details
							</div>
						</div>
						{activeTab === 1 && (
							<div className="individualTabDetails">
								{createNewFormSection(
									vendorFormFields.section2
								)}
							</div>
						)}
						{activeTab === 2 && (
							<div className="individualTabDetails">
								{createNewFormSection(
									vendorFormFields.section3
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);
};

export default VendorForm;
